<script setup lang="ts">
import Notification from '@frontend/ui-kit/src/components/Base/Notification.vue';
import Footer from '~/components/shared/Common/Footer.vue';
import Logo from '~/components/shared/Common/Logo.vue';
import { useNotificationStore } from "~/stores/alert/useNotificationStore";

const store = useNotificationStore();
</script>

<template>
    <v-app class="app">
        <v-layout class="layout">
            <div class="column">
                <div class="column-container">
                    <div class="header">
                        <Logo />
                    </div>
                </div>
            </div>
            <div class="main">
                <div class="main-container">
                    <slot />
                </div>

            </div>

            <div class="column"/>
            <Notification :messages="store.messages"/>
        </v-layout>
        <div class="footer">
            <Footer />
        </div>
    </v-app>
</template>

<style scoped lang="scss">
    .app {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        flex: 1 0 auto;
        background-color: $main-bg-color;

        :deep(.v-application__wrap) {
            justify-content: center;
            align-items: center;
            align-self: stretch;
        }
    }

    .layout {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        flex: 1 0 auto;

        @include responsive( 'mobile' ) {
            flex-direction: column;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex-shrink: 0;

        @include responsive( 'mobile' ) {
            width: inherit;
            padding: 0;
        }
        @include responsive( 'desktop' ) {
            width: 240px;
            padding: 60px var(--Padding-pa-6, 24px);
        }
        @include responsive( 'desktop-small' ) {
            padding: 80px 40px;
            width: 360px;
        }

        .column-container {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            align-items: flex-start;
            align-self: stretch;
            gap: 120px;

            @include responsive( 'mobile' ) {
                justify-content: center;
                align-items: center;
                flex: inherit;
                gap: 0;
            }

            .header {
                @include responsive('mobile') {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    padding: 20px 12px;
                }

                .logo {
                    display: flex;
                    padding: 0px 12px;
                    align-items: flex-start;
                    gap: var(--Padding-pa-6, 24px);
                    color: $logo-color;
                }
            }
        }
    }

    .main {
        display: flex;
        padding: 32px 24px;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: 32px;
        flex: 1 0 0;

        @include responsive( 'desktop' ) {
            max-width: 500px;
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
        }

        @include responsive( 'mobile' ) {
            padding: 0;
            justify-content: center;
        }

        .main-container {
            display: flex;
            flex-direction: column;
            padding: 40px 80px 80px 0;
            gap: 40px;
            flex: 1 0 0;
            align-self: stretch;
            align-items: flex-start;
            justify-content: flex-start;

            @include responsive( 'mobile' ) {
                padding: 32px 24px;
            }
            @include responsive( 'desktop' ) {
                padding: 24px 32px;
                gap: 24px;
            }
            @include responsive( 'desktop-small' ) {
                padding: 40px;
            }
            @include responsive( 'desktop-medium' ) {
                padding: 40px 80px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        gap: 16px;
        padding: 32px 24px;
    }
</style>